import { theme as baseTheme, extendTheme } from '@chakra-ui/react'

import * as components from './components'
import * as foundations from './foundations'

export const theme = extendTheme({
  ...foundations,
  components: { ...components },
  colors: { ...baseTheme.colors, brand: baseTheme.colors.blue },
  space: {
    '4.5': '1.125rem',
  },
})
