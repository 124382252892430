import { Database as SupabaseDatabase } from '@/supabase/types'
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { useSupabaseClient } from '@supabase/auth-helpers-react'

export type { SupabaseDatabase }

export const supabaseClient = createBrowserSupabaseClient<SupabaseDatabase>()

export function useTypedSupabaseClient() {
  return useSupabaseClient<SupabaseDatabase>()
}
