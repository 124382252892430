import { JSONValue } from 'superjson/dist/types'
import { z } from 'zod'

export type Institution = z.infer<typeof institutionSchema>
export const institutionSchema = z.discriminatedUnion('type', [
  // custom value
  z.object({
    type: z.literal('manual'),
    value: z.string(),
  }),
  // picked values from (data as google.maps.places.AutocompletePrediction)
  z.object({
    type: z.literal('googlePlace'),
    value: z.string(), // data.structured_formatting.main_text
    placeId: z.string(), // data.place_id
  }),
])

export function getInstitution(institution: JSONValue | null) {
  if (!institution) return null
  const parsed = institutionSchema.safeParse(institution)
  if (parsed.success) {
    return parsed.data
  }
  return null
}
