import { COUNTRIES } from '../lib/countries'
import { OCCUPATIONS } from '../lib/occupations'
import type { RevenueCatEntitlement } from '../lib/revenue-cat/revenue-cat'
import { revenueCatEntitlementIsActive } from '../lib/revenue-cat/revenue-cat-utils'
import { isValidNpi } from '../lib/utils/isValidNpi'
import { STATES } from '../lib/utils/states'
import { getIdNameRecord, idNameRecordSchema } from './idNameRecord'
import { getInstitution, institutionSchema } from './institution'
import type { ProfileWithComputedFields } from './profiles'

import { Profile } from '@prisma/client'
import Stripe from 'stripe'
import { JSONArray } from 'superjson/dist/types'
import { z } from 'zod'

const dateOfBirth = z.date().max(new Date(), { message: 'Invalid birth date' })
const firstName = z.string().min(1)
const lastName = z.string().min(1)
const occupation = z.string()
const proId = z.string().nullish()

export const occupationOtherSchema = z
  .string()
  .regex(/^[a-z]{3}/i, 'Please enter your occupation')
  .optional()
  .or(z.literal(''))

export function getProfileDistinctId(profile: {
  revenueCatCustomerId?: string | null
  id: string
}) {
  return profile.revenueCatCustomerId ?? profile.id
}

export const profileCmeSchemaInput = z.object({
  dateOfBirth,
  licenseCountry: z.enum(COUNTRIES.map((c) => c.id) as [string, ...string[]]),
  licenseId: z.string().min(1).nullish(),
  licenseState: z
    .enum(STATES.map((s) => s.state.isoCode) as [string, ...string[]])
    .nullish(),
  firstName,
  lastName,
  occupation,
  proId,
})
export const profileCmeSchema = profileCmeSchemaInput
  .refine(
    (data) => {
      return (
        (data.proId ?? '').trim() === '' ||
        isValidNpi(String(data.proId)) ||
        data.occupation === 'nurse' ||
        data.occupation === 'pharmacist' ||
        data.occupation === 'physician-assistant'
      )
    },
    {
      message: 'This does not look like a valid NPI.',
      path: ['proId'],
    },
  )
  .refine(
    (data) =>
      (['US', 'CA'].includes(data.licenseCountry) && data.licenseState) ||
      !['US', 'CA'].includes(data.licenseCountry),
    {
      message: 'Please Enter Your State/Province',
      path: ['licenseState'],
    },
  )
  .refine(
    (data) =>
      (['US'].includes(data.licenseCountry) && data.licenseId) ||
      !['US'].includes(data.licenseCountry),
    {
      message: 'Please Enter License ID',
      path: ['licenseID'],
    },
  )

export const profileSchemaInput = z.object({
  city: z.string().min(1).optional().nullable(),
  country: z
    .enum(COUNTRIES.map((c) => c.id) as [string, ...string[]])
    .optional()
    .nullable(),
  firstName,
  firstNameExternal: z.string().nullable(),
  identifierExternal: z.string().nullable(),
  institution: institutionSchema,
  howDidYouHearAboutUs: z.string().optional().nullable(),
  lastName,
  lastNameExternal: z.string().nullable(),
  occupation,
  occupationExternal: z.string().nullable(),
  occupationOther: occupationOtherSchema.nullable(),
  professionalTitleExternal: z.string().nullable(),
  proId,
  proIdExternal: z.string().nullable(),
  referralCode: z.string().optional(),
  registeredFromPlatform: z.string().nullable(),
  revenueCatCustomerId: z.string().nullable(),
  specialtyShortExternal: z.string().nullable(),
  specialtyExternal: z.string().nullable(),
  stateExternal: z.string().nullable(),
  specialties: idNameRecordSchema
    .array()
    .min(1, { message: 'Please select at least one specialty.' })
    .default([]),
  state: z.string().min(1).nullish(),
  stripeCustomerId: z.string().nullish(),
})

export type ProfileCmeSchema = z.infer<typeof profileCmeSchema>

export function validateProfileForCme(
  profile: ProfileWithComputedFields | Profile,
  input: Partial<z.input<typeof profileCmeSchema>>,
) {
  return profileCmeSchema.parse({
    ...profile,
    ...input,
  })
}

export const profileExternalMetadataPayload = z.object({
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  createdAt: z.string(),
  institution: z.string().optional().nullable(),
  howDidYouHearAboutUs: z.string().optional().nullable(),
  occupation: z.string().optional().nullable(),
  occupationOther: occupationOtherSchema,
  profession: z.string().optional().nullable(),
  proId: z.string().optional().nullable(),
  registeredFromPlatform: z.string().optional().nullable(),
  specialties: z.array(z.string()).optional().nullable(),
  state: z.string().optional().nullable(),
  stripeCustomerId: z.string().optional().nullable(),
  subscriptionStatus: z.string(),
  userId: z.string(),
})

/**
 * A function that generates a represenation of the profile to
 * store in external services such as Mixpanel, Stripe and Zendesk
 */
export function profileToExternalPayload(profile: ProfileWithComputedFields) {
  return {
    aiQuestionsRemaining: profile.aiQuestionsRemaining,
    // If a user had an id in v2 (we're now in auth v3), we should use it
    // as a distinct id in mixpanel in order to not duplicate their profile
    distinctId: profile.revenueCatCustomerId || profile.id,
    firstName: profile.firstName?.slice(0, 500),
    lastName: profile.lastName?.slice(0, 500),
    email: profile.email,
    fullName: `${profile.firstName} ${profile.lastName}`.slice(0, 500),
    metadata: {
      city: profile.city,
      country: profile.country,
      createdAt: new Date(profile.createdAt).toISOString(),
      howDidYouHearAboutUs: profile.howDidYouHearAboutUs,
      institution: getInstitution(profile.institution)?.value.slice(0, 500),
      occupation: profile.occupation,
      occupationOther: profile.occupationOther ?? undefined,
      profession: OCCUPATIONS.find((o) => o.id === profile.occupation)?.id,
      proId: profile.proId,
      registeredFromPlatform: profile.registeredFromPlatform,
      specialties: (profile.specialties as JSONArray)
        ?.map((s) => getIdNameRecord(s)?.name)
        .filter(
          (name): name is Exclude<typeof name, undefined> =>
            name !== undefined && name !== '' && name !== null,
        ),
      state: profile.state,
      stripeCustomerId: profile.stripeCustomerId,
      subscriptionStatus: profileHasActiveSubscription(profile)
        ? 'active'
        : 'inactive',
      userId: String(profile.id),
    } satisfies z.input<typeof profileExternalMetadataPayload>,
  }
}

export function getProfileActiveSubscription(profile: Partial<Profile>): {
  expiryDate: Date
  startDate: Date
  platform: 'web' | 'iOS' | 'Android'
  status: Stripe.Subscription['status'] | null
} | null {
  const revenueCatEntitlements =
    profile.revenueCatEntitlements as RevenueCatEntitlement
  if (revenueCatEntitlementIsActive(revenueCatEntitlements)) {
    return {
      expiryDate: new Date(revenueCatEntitlements.complete?.expires_date ?? 0),
      platform: revenueCatEntitlements?.complete?.product_plan_identifier
        ? 'Android'
        : 'iOS',
      startDate: new Date(revenueCatEntitlements.complete?.purchase_date ?? 0),
      // we don't have the subscription status for RevenueCat in our database
      status: null,
    }
  }

  const stripeSubscription = (
    profile.stripeSubscriptions as Stripe.Subscription[] | null
  )?.find((s) => {
    return s.status === 'active' || s.status === 'trialing'
  })
  if (!stripeSubscription) return null

  return {
    expiryDate: new Date(stripeSubscription.current_period_end * 1000),
    platform: 'web',
    startDate: new Date(stripeSubscription.created * 1000),
    status: stripeSubscription.status,
  }
}

export function profileHasActiveSubscription(profile: {
  lifetimePurchasePaymentIntentId: string | null
  revenueCatEntitlements: unknown
  stripeSubscriptions: unknown
}) {
  return (
    revenueCatEntitlementIsActive(
      profile.revenueCatEntitlements as RevenueCatEntitlement,
    ) ||
    profile.lifetimePurchasePaymentIntentId !== null ||
    !!(profile.stripeSubscriptions as Stripe.Subscription[] | null)?.find(
      (s) => {
        return s.status === 'active' || s.status === 'trialing'
      },
    )
  )
}

export function profileHasCompleteSubscription(profile: Partial<Profile>) {
  const entitlement = (profile.revenueCatEntitlements as RevenueCatEntitlement)
    ?.complete

  return (
    !!(profile.stripeSubscriptions as Stripe.Subscription[] | null)?.find(
      (s) => {
        return s.status === 'active' || s.status === 'trialing'
      },
    ) ||
    (entitlement &&
      !['basic', 'student'].some(
        (k) => entitlement?.product_identifier?.includes(k),
      ))
  )
}
export const signupFormOccupationOther = z.string().min(3)
export const signupFormSchema = z
  .object({
    city: z.string().min(1).optional(),
    country: idNameRecordSchema.optional(),
    firstName: z.string().trim().min(1),
    howDidYouHearAboutUs: z.string().optional().nullable(),
    lastName: z.string().trim().min(1),
    occupation: idNameRecordSchema,
    occupationOther: occupationOtherSchema,
    institution: institutionSchema,
    specialties: idNameRecordSchema
      .array()
      .min(1, { message: 'Please select at least one specialty.' })
      .default([]),
    state: z.string().min(1).optional(),
    npi: z.string().optional(),
  })
  .refine(
    (data) => {
      return (
        data.occupation?.id !== 'other' ||
        signupFormOccupationOther.safeParse(data.occupationOther).success
      )
    },
    {
      message: 'Please provide your occupation',
      path: ['occupationOther'],
    },
  )
  .refine(
    (data) => {
      return (
        isValidNpi(String(data.npi)) ||
        data.occupation?.id !== 'physician' ||
        data.country?.id !== 'US'
      )
    },
    {
      message: 'This does not look like a valid NPI.',
      path: ['npi'],
    },
  )

export const signupFormSchemaWithHowDidYouHearAboutUs = signupFormSchema.refine(
  (data) => {
    return (data.howDidYouHearAboutUs ?? '').length > 2
  },
  {
    message: 'Please tell us how you heard about us',
    path: ['howDidYouHearAboutUs'],
  },
)

export type SignUpForm = z.infer<typeof signupFormSchema>
export type SignupFormWithHowDidYouHearAboutUs = z.infer<
  typeof signupFormSchemaWithHowDidYouHearAboutUs
>
