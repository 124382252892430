import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useRouter } from 'next/router'

type GoogleTagContextType = {
  isGoogleTagReady: boolean
}

const GoogleTagContext = createContext<GoogleTagContextType | undefined>(
  undefined,
)

export const useGoogleTag = () => {
  const context = useContext(GoogleTagContext)
  if (!context) {
    throw new Error('useGoogleTag must be used within a GoogleTagProvider')
  }
  return context
}

// A context is used here because there are multiple ad banners on a page
export const GoogleTagProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isGoogleTagReady, setGoogleTagReady] = useState(false)
  const router = useRouter()

  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] }
    // K: These are the commands that are run when the googletag object is ready
    // It's faulty to assume it's ready when window.googletag is defined
    window.googletag.cmd.push(() => {
      setGoogleTagReady(true)
    })
  }, [])

  const removeSlots = useCallback(() => {
    const { googletag } = window
    googletag.cmd.push(function () {
      googletag.destroySlots()
    })
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', removeSlots)
    return () => {
      router.events.off('routeChangeComplete', removeSlots)
    }
  }, [router, removeSlots])

  return (
    <GoogleTagContext.Provider value={{ isGoogleTagReady }}>
      {children}
    </GoogleTagContext.Provider>
  )
}
