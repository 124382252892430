/** Validates a National Provider Identifier Standard (NPI) */
export function isValidNpi(npi: string): boolean {
  // Remove extra spaces
  npi = npi.trim()

  // Abort early if we aren't left with a 10-digit string OR a
  // 15-digit string that starts with '80840'
  if (!/^(?:80840)?\d{10}$/.test(npi)) {
    return false
  }

  let sum = npi.length === 15 ? 0 : 24

  let i = npi.length
  let j = 0

  while (i !== 0) {
    let tmp = npi.charCodeAt(i - 1) - '0'.charCodeAt(0)

    if (j++ % 2 !== 0) {
      tmp <<= 1

      if (tmp > 9) {
        tmp -= 10
        tmp++
      }
    }

    sum += tmp
    i--
  }

  return sum % 10 === 0
}
