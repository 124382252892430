import { useGeoLocation } from './useGeoLocation'

const gdprCountryCodes = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'GB', // United Kingdom
]

// show cookie banner for GDPR countries and for California for the CCPA
export function useUserIsInCookieBannerRegion(args: { enabled: boolean }) {
  const location = useGeoLocation(args)

  if (location.isFetching) return 'loading'

  if (!location.data || !args.enabled) return null

  return (
    gdprCountryCodes.includes(location.data?.country) ||
    (location.data?.country === 'US' && location.data?.countryRegion === 'CA')
  )
}
