export const STATES = [
  { country: 'US', state: { name: 'Alabama', isoCode: 'AL' } },
  { country: 'US', state: { name: 'Alaska', isoCode: 'AK' } },
  { country: 'US', state: { name: 'Arizona', isoCode: 'AZ' } },
  { country: 'US', state: { name: 'Arkansas', isoCode: 'AR' } },
  { country: 'US', state: { name: 'California', isoCode: 'CA' } },
  { country: 'US', state: { name: 'Colorado', isoCode: 'CO' } },
  { country: 'US', state: { name: 'Connecticut', isoCode: 'CT' } },
  { country: 'US', state: { name: 'Delaware', isoCode: 'DE' } },
  { country: 'US', state: { name: 'District of Columbia', isoCode: 'DC' } },
  { country: 'US', state: { name: 'Florida', isoCode: 'FL' } },
  { country: 'US', state: { name: 'Georgia', isoCode: 'GA' } },
  { country: 'US', state: { name: 'Hawaii', isoCode: 'HI' } },
  { country: 'US', state: { name: 'Idaho', isoCode: 'ID' } },
  { country: 'US', state: { name: 'Illinois', isoCode: 'IL' } },
  { country: 'US', state: { name: 'Indiana', isoCode: 'IN' } },
  { country: 'US', state: { name: 'Iowa', isoCode: 'IA' } },
  { country: 'US', state: { name: 'Kansas', isoCode: 'KS' } },
  { country: 'US', state: { name: 'Kentucky', isoCode: 'KY' } },
  { country: 'US', state: { name: 'Louisiana', isoCode: 'LA' } },
  { country: 'US', state: { name: 'Maine', isoCode: 'ME' } },
  { country: 'US', state: { name: 'Maryland', isoCode: 'MD' } },
  { country: 'US', state: { name: 'Massachusetts', isoCode: 'MA' } },
  { country: 'US', state: { name: 'Michigan', isoCode: 'MI' } },
  { country: 'US', state: { name: 'Minnesota', isoCode: 'MN' } },
  { country: 'US', state: { name: 'Mississippi', isoCode: 'MS' } },
  { country: 'US', state: { name: 'Missouri', isoCode: 'MO' } },
  { country: 'US', state: { name: 'Montana', isoCode: 'MT' } },
  { country: 'US', state: { name: 'Nebraska', isoCode: 'NE' } },
  { country: 'US', state: { name: 'Nevada', isoCode: 'NV' } },
  { country: 'US', state: { name: 'New Hampshire', isoCode: 'NH' } },
  { country: 'US', state: { name: 'New Jersey', isoCode: 'NJ' } },
  { country: 'US', state: { name: 'New Mexico', isoCode: 'NM' } },
  { country: 'US', state: { name: 'New York', isoCode: 'NY' } },
  { country: 'US', state: { name: 'North Carolina', isoCode: 'NC' } },
  { country: 'US', state: { name: 'North Dakota', isoCode: 'ND' } },
  { country: 'US', state: { name: 'Ohio', isoCode: 'OH' } },
  { country: 'US', state: { name: 'Oklahoma', isoCode: 'OK' } },
  { country: 'US', state: { name: 'Oregon', isoCode: 'OR' } },
  { country: 'US', state: { name: 'Pennsylvania', isoCode: 'PA' } },
  { country: 'US', state: { name: 'Puerto Rico', isoCode: 'PR' } },
  { country: 'US', state: { name: 'Rhode Island', isoCode: 'RI' } },
  { country: 'US', state: { name: 'South Carolina', isoCode: 'SC' } },
  { country: 'US', state: { name: 'South Dakota', isoCode: 'SD' } },
  { country: 'US', state: { name: 'Tennessee', isoCode: 'TN' } },
  { country: 'US', state: { name: 'Texas', isoCode: 'TX' } },
  { country: 'US', state: { name: 'Utah', isoCode: 'UT' } },
  { country: 'US', state: { name: 'Vermont', isoCode: 'VT' } },
  { country: 'US', state: { name: 'Virginia', isoCode: 'VA' } },
  { country: 'US', state: { name: 'Washington', isoCode: 'WA' } },
  { country: 'US', state: { name: 'West Virginia', isoCode: 'WV' } },
  { country: 'US', state: { name: 'Wisconsin', isoCode: 'WI' } },
  { country: 'US', state: { name: 'Wyoming', isoCode: 'WY' } },
  { country: 'CA', state: { name: 'Alberta', isoCode: 'AB' } },
  { country: 'CA', state: { name: 'British Columbia', isoCode: 'BC' } },
  { country: 'CA', state: { name: 'Manitoba', isoCode: 'MB' } },
  { country: 'CA', state: { name: 'New Brunswick', isoCode: 'NB' } },
  {
    country: 'CA',
    state: { name: 'Newfoundland and Labrador', isoCode: 'NL' },
  },
  { country: 'CA', state: { name: 'Northwest Territories', isoCode: 'NT' } },
  { country: 'CA', state: { name: 'Nova Scotia', isoCode: 'NS' } },
  { country: 'CA', state: { name: 'Nunavut', isoCode: 'NU' } },
  { country: 'CA', state: { name: 'Ontario', isoCode: 'ON' } },
  { country: 'CA', state: { name: 'Prince Edward Island', isoCode: 'PE' } },
  { country: 'CA', state: { name: 'Quebec', isoCode: 'QC' } },
  { country: 'CA', state: { name: 'Saskatchewan', isoCode: 'SK' } },
  { country: 'CA', state: { name: 'Yukon', isoCode: 'YT' } },
]
