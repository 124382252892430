import { useEffect } from 'react'

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const pageview = () => {
  window.fbq && window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const FBEvent = (name: string, options = {}) => {
  window.fbq && window.fbq('track', name, options)
}

export const useFBViewResource = () => {
  useEffect(() => {
    FBEvent('track', 'ViewContent')
  }, [])
}
