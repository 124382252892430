export function isMobileHost() {
  return (
    getHostUrl() === cleanHostUrl(process.env.NEXT_PUBLIC_MOBILE_HOST ?? '')
  )
}
export function cleanHostUrl(url: string) {
  // Prefix protocol if it's not included
  if (!url.startsWith('http')) {
    url = `http://${url}`
  }

  // Make sure anything but localhost addresses use the https protocol
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development') {
    url = url.replace(
      /^http(?!s):\/\/(?!localhost|0\.0\.0\.0|127\.0\.0\.0)/,
      'https://',
    )
  }

  // Remove trailing slash(es?)
  url = url.replace(/\/+$/, '')

  return url
}

/** Return the current host url without trailing slash */
export function getHostUrl() {
  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development' &&
    typeof window !== 'undefined'
  ) {
    return cleanHostUrl(window.location.origin)
  }

  const hostUrl =
    process?.env?.NEXT_PUBLIC_HOST ?? // Set this to your site URL in production env.
    process?.env?.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
    'http://localhost:3000'

  return cleanHostUrl(hostUrl)
}
