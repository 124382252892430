import { COUNTRIES } from 'p-data/src/lib/countries'
import { OCCUPATIONS } from 'p-data/src/lib/occupations'
import {
  IdNameRecord,
  idNameRecordSchema,
} from 'p-data/src/services/idNameRecord'
import { Institution, institutionSchema } from 'p-data/src/services/institution'

import { RouterOutput } from '@/lib/trpcClient'
import { SafeParseSuccess } from 'zod'

export const normalizeProfileValues = (
  profile?: RouterOutput['profiles']['currentUser'],
) => {
  if (!profile) {
    return undefined
  }

  return {
    country: COUNTRIES.find((c) => c.id === profile.country),
    email: profile.email,
    firstName: profile.firstName ?? '',
    howDidYouHearAboutUs: profile.howDidYouHearAboutUs ?? '',
    lastName: profile.lastName ?? '',
    occupation: OCCUPATIONS.find((occ) => occ.id === profile.occupation),
    occupationOther: profile.occupationOther ?? undefined,
    institution: (
      institutionSchema.safeParse(
        profile.institution,
      ) as SafeParseSuccess<Institution>
    ).data,
    specialties:
      (
        idNameRecordSchema
          .array()
          .safeParse(profile.specialties) as SafeParseSuccess<IdNameRecord[]>
      ).data ?? [],
    npi: profile.proId || undefined,
  }
}
