import { useQuery } from '@tanstack/react-query'

export function useGeoLocation(args: { enabled: boolean }) {
  return useQuery<{
    country: string
    countryRegion: string
  }>({
    enabled: args.enabled,
    queryKey: ['getLocationInfo'],
    queryFn: async () => {
      return (await fetch('/api/location')).json()
    },
    retry: 5,
    retryDelay: 0,
  })
}
