import { RouterOutput } from '@/lib/trpcClient'
import * as Sentry from '@sentry/nextjs'

// A function that sets up the user session for tracking in Sentry
export const setSentrySessionTracking = (
  profile: RouterOutput['profiles']['currentUser'],
) => {
  if (!profile) return
  Sentry.setUser({
    id: profile.id,
    username: `${profile.firstName} ${profile.lastName}`,
    email: profile.email,
  })
  Sentry.setTag('subscribed', profile.hasSubscription)
}
