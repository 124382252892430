import { getHostUrl } from '@/utils/getHostUrl'
import mixpanel from 'mixpanel-browser'

const isDev = process.env.NODE_ENV === 'development'

if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
  throw new Error(`Missing env var NEXT_PUBLIC_MIXPANEL_TOKEN`)
}

if (!isDev) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    api_host: `${getHostUrl()}/mp`,
    ...(isDev
      ? {
          debug: true,
          ignore_dnt: true,
        }
      : {}),
  })
}

function noop() {}
const mixpanelClient = isDev
  ? {
      track: function (evt: unknown, params?: unknown) {
        // eslint-disable-next-line no-console
        console.log(`Firing event ${evt} with properties`, params)
      },
      identify: noop,
      reset: noop,
      opt_out_tracking: noop,
    }
  : mixpanel

export { mixpanelClient as mixpanel }
