export const OCCUPATIONS = [
  {
    id: 'physician',
    name: 'Physician',
    icon: 'profession-physician',
  },
  { id: 'resident', name: 'Resident Physician', icon: 'profession-resident' },
  {
    id: 'physician-assistant',
    name: 'Physician Assistant',
    icon: 'profession-other',
  },
  {
    id: 'nurse',
    name: 'Nurse',
    icon: 'profession-nurse',
  },
  {
    id: 'nurse-practitioner',
    name: 'Nurse Practitioner',
    icon: 'profession-nurse',
  },
  {
    id: 'student',
    name: 'Medical / Nursing Student',
    icon: 'profession-student',
  },
  {
    id: 'pharmacist',
    name: 'Pharmacist',
    icon: 'specialty-medical-biochemistry',
  },
  {
    id: 'other',
    name: 'Other Healthcare Professional',
    icon: 'profession-other',
  },
] as const
