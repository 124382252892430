import React, { createContext, useContext, useEffect, useState } from 'react'

type SidebarExpansionContextType = {
  isExpanded: boolean | null
  toggleSidebar: () => void
}

const SidebarExpansionContext =
  createContext<SidebarExpansionContextType | null>(null)

export const useSidebar = () => {
  const context = useContext(SidebarExpansionContext)
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarExpansionProvider')
  }
  return context
}

export const SidebarExpansionProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  useEffect(() => {
    setIsExpanded((localStorage.getItem('sidebar-expanded') ?? '1') === '1')
  }, [])

  const toggleSidebar = () => {
    setIsExpanded((prev) => {
      const newValue = !prev
      localStorage.setItem('sidebar-expanded', newValue ? '1' : '0')
      if (prev === null) return true
      return newValue
    })
  }

  return (
    <SidebarExpansionContext.Provider value={{ isExpanded, toggleSidebar }}>
      {children}
    </SidebarExpansionContext.Provider>
  )
}
