const closeButton = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
    _focusVisible: {
      boxShadow: 'outline',
    },
  },
}

export default closeButton
