import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { usePathname } from 'next/navigation'

import { useProfile } from '@/utils/useProfile'

const launchesKey = 'launchesForPaywall'
const paywallKey = 'sawPaywallAt'

interface PaywallContext {
  checkForTrial: boolean
  eligibleForDiscount: boolean
  hidePaywall: () => void
  maybeShowPaywall: () => void
  shouldShowPaywall: boolean
  showPaywall: (props?: {
    title?: string
    titleDiscount?: string
    titleTrial?: string
    trigger?: string
  }) => void
  title?: string
  titleDiscount?: string
  titleTrial?: string
  trigger: string
}
export const PaywallContext = createContext<PaywallContext | null>(null)

function getSawPaywallAt() {
  return parseInt(localStorage.getItem(paywallKey) ?? '0')
}

export function PaywallProvider(props: { children: React.ReactNode }) {
  const [hasLaunched, setHasLaunched] = useState(false)
  const { profile } = useProfile()
  const navigationUrl = usePathname()
  const isOnAI = navigationUrl?.includes('/ai')

  const [trigger, setTrigger] = useState<string>('')

  const [title, setTitle] = useState<string | undefined>(undefined)
  const [titleDiscount, setTitleDiscount] = useState<string | undefined>(
    undefined,
  )
  const [titleTrial, setTitleTrial] = useState<string | undefined>(undefined)
  const [eligibleForDiscount, setEligibleForDiscount] = useState<boolean>(true)

  const [shouldShowPaywall, setShouldShowPaywall] = useState(false)

  const showPaywall = useCallback(
    (props?: {
      eligibleForDiscount?: boolean
      title?: string
      titleDiscount?: string
      titleTrial?: string
      trigger?: string
    }) => {
      if (!profile) return
      if (shouldShowPaywall) return
      if (profile?.hasSubscription) return
      setEligibleForDiscount(props?.eligibleForDiscount ?? true)
      setTitle(props?.title)
      setTitleDiscount(props?.titleDiscount)
      setTitleTrial(props?.titleTrial)
      setTrigger(props?.trigger ?? '')
      if (!getSawPaywallAt()) {
        localStorage.setItem('sawPaywallAt', Date.now().toString())
      }
      setShouldShowPaywall(true)
    },
    [shouldShowPaywall, profile],
  )

  useEffect(() => {
    if (!profile || hasLaunched) return
    if (profile?.hasSubscription) return
    let launches = parseInt(localStorage.getItem(launchesKey) ?? '0')
    launches += 1
    localStorage.setItem(launchesKey, launches.toString())
    const searchParams = new URL(document.location.toString()).searchParams

    if (searchParams?.get('subscribe') === '1') {
      showPaywall({
        trigger: 'url-trigger',
      })
    } else if (
      launches > 5 &&
      launches % 5 === 0 &&
      getSawPaywallAt() &&
      !isOnAI
    ) {
      // during the initial onboarding there's a lot of launches that accumulate so
      // we're skipping the first 5 launches trigger
      // Disable during the ad modal campaign
      showPaywall({ trigger: '5th launch' })
    }
    setHasLaunched(true)
  }, [isOnAI, profile, showPaywall, hasLaunched, shouldShowPaywall])

  const maybeShowPaywall = useCallback(() => {
    if (!profile) return
    if (isOnAI) return
    if (profile?.hasSubscription) return
    const now = new Date()
    const halfDayInMs = 1000 * 60 * 60 * 12
    const didUserSignUpLessThanHalfDayAgo =
      now.getTime() - new Date(profile?.createdAt ?? now).getTime() <
      halfDayInMs

    if (!getSawPaywallAt() && didUserSignUpLessThanHalfDayAgo) {
      showPaywall({ trigger: 'onboarding', eligibleForDiscount: false })
      localStorage.setItem('sawPaywallAt', Date.now().toString())
    }
  }, [isOnAI, profile, showPaywall])

  const contextValue = useMemo(
    () => ({
      checkForTrial: true,
      eligibleForDiscount,
      hidePaywall: () => {
        setShouldShowPaywall(false)
      },
      maybeShowPaywall,
      shouldShowPaywall,
      showPaywall,
      title,
      titleDiscount,
      titleTrial,
      trigger,
    }),
    [
      eligibleForDiscount,
      maybeShowPaywall,
      setShouldShowPaywall,
      shouldShowPaywall,
      showPaywall,
      title,
      titleDiscount,
      titleTrial,
      trigger,
    ],
  )

  return (
    <PaywallContext.Provider value={contextValue}>
      {props.children}
    </PaywallContext.Provider>
  )
}

export function usePaywallContext() {
  const ctx = useContext(PaywallContext)
  if (ctx === null) throw new Error('missing Paywall context')
  return ctx
}
