const variants = {
  menu: () => ({
    borderRadius: 'lg',
    _hover: {
      textDecoration: 'none',
      bg: 'bg-subtle',
    },
  }),
}

const link = {
  variants,
}

export default link
