// Hue Down pallette based on Pathway's official brand color, keyed '400' here.
// https://smart-swatch.netlify.app/#5371c7
export const pathwayBrandColorPalette = {
  50: '#eff6ff',
  100: '#dbeafe',
  200: '#bfdbfe',
  300: '#93c5fd',
  400: '#60a5fa',
  500: '#3b82f6',
  600: '#2563eb',
  700: '#1d4ed8',
  800: '#1e40af',
  900: '#1e3a8a',
  950: '#172554',
} as const
